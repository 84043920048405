import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Main',
    meta: { title: 'Tworzenie oprogramowania i systemów informatycznych - Complaia Systems' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Main.vue')
  },
  {
    path: '/kontakt',
    name: 'Contact',
    meta: { title: 'Skontaktuj się z nami - Complaia Systems' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Contact.vue')
  },
  {
    path: '/firma',
    name: 'Company',
    meta: { title: 'Dowiedz się więcej - Complaia Systems' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Company.vue')
  },
  {
    path: '/uslugi',
    name: 'Services',
    meta: { title: 'Nasze usługi - Complaia Systems' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Services.vue')
  },
  {
    path: '/technologia',
    name: 'Technology',
    meta: { title: 'Technologia - Complaia Systems' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Technology.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    meta: { title: 'Blog software house - Complaia Systems' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Blog.vue')
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/PageNotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

